<template>
  <div class="screening">
    <!-- <b-card>
      <p class="p-nav cursor-pointer" @click="StatusEvent(index)" v-for="(item, index) in screeningSum.status">
        <span :class="{'present':activeIndex == index}">
          {{item}}
        </span>
      </p>
    </b-card> -->
    <b-card class="pcTab">
      <p class="p-nav cursor-pointer" @click="GenreEvent(item.type)" v-for="(item, index) in genreList"
        :key="index">
        <span :class="{ present: genreIndex == item.type }">
          {{ item.name }}
        </span>
      </p>
    </b-card>
    <b-card class="phoneTab">
      <h4>All</h4>
      <el-collapse v-model="activeName" accordion>
        <el-collapse-item :title="genreIndex|tabFilter(genreIndex)" name="1">
          <p class="p-nav cursor-pointer" @click="GenreEvent(item.type)" v-for="(item, index) in genreList"
            :key="index">
            <span :class="{ present: genreIndex == item.type }">
              {{ item.name }}
            </span>
          </p>
        </el-collapse-item>
      </el-collapse>
    </b-card>
  </div>
</template>

<script>
  import bus from '@/Utils/Bus'
  export default {
    name: 'demo',
    // 组件参数 接收父组件数据
    props: {},
    // 局部注册组件
    components: {},
    // 组件状态值
    data() {
      return {
        // screeningSum: {
        //   status: ['All', 'Pending Payment', 'Refunding'],
        //   genre: ['Project', 'Event', 'Course', 'Meeting', 'Course papers', 'Course certificate', 'Donation', 'Top up', 'Program', 'Membership fee']
        // },
        genreList: [
          { name: 'Event', type: '2' },
          { name: 'Meeting', type: '4' },
          { name: 'Program', type: '6' },
          { name: 'Donation', type: '8' },
          { name: 'Membership Fee', type: '5' },
        ],
        activeIndex: 0,
        genreIndex: 0,
        activeName: 1,
      }
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {},
    filters: {
      tabFilter(val) {
        return val == 2 ? 'Event' : val == 4 ? 'Meeting': val == 6 ? 'Program': val == 8 ? 'Donation': 'Membership Fee'
      }
    },
    // 组件实例创建完成，DOM未生成，
    created() {

    },
    mounted() {
      // this.GenreEvent()
      // 暂时 默认返回 状态 3
      if (this.$route.query.model_type) {
        this.genreIndex = this.$route.query.model_type
        // bus.$emit('GenreBus', this.$route.query.type)
        // this.$emit('GenreEvent', this.$route.query.type)
      }
      // this.$emit('GenreEvent', 3)
    },
    // 组件方法
    methods: {
      // StatusEvent(index) {
      //   this.activeIndex = index
      //   this.$emit('StatusEvent', index)
      // },

      GenreEvent(model_type) {
        this.genreIndex = model_type
        // this.$emit("GenreEvent", index)
        this.$router.replace(`/MyOtherOrder?model_type=${model_type}`)
        // bus.$emit('GenreBus', index + 1)
      }
    },
    // 组件 DOM 已经更新
    updated() { },
    // 实例销毁之前调用。
    beforeDestroy() {
      // this.$bus.$off('GenreBus')
    },
    // Vue 实例销毁后调用,事件监听器会被移除
    destroyed() { }

  }

</script>

<style scoped lang="less">
@media(min-width: 600px) {
  .phoneTab{
    display: none;
  }
}
@media(max-width: 600px) {
  .pcTab {
    display: none;
  }
  ::v-deep .el-collapse-item__header{
    font-size: 16px;
    font-weight: 400;
    border-bottom: 0;
  }
  ::v-deep .el-collapse-item__content{
    padding-bottom: 0;
  }
}
  .screening {
    .present {
      // color: var(--iq-primary);
      color: #50b5ff;
    }
  }
</style>