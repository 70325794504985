<template>
  <div class="MyOtherOrder">
    <div class="row">
      <div class="col-sm-3">
        <screening @StatusEvent="StatusEvent" @GenreEvent="GenreEvent" />
      </div>
      <div class="col-sm-9 phoneMargin">
        <orderlist />
      </div>
    </div>

  </div>
</template>

<script>
import screening from './components/screening/screening'
import orderlist from './components/orderlist/orderlist'
export default {
  name: 'demo',
  // 组件参数 接收父组件数据
  props: {},
  // 局部注册组件
  components: {
    screening,
    orderlist
  },
  // 组件状态值
  data () {
    return {
      order_type: ''
    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  // 组件实例创建完成，DOM未生成，
  created () {},
  mounted () {},
  // 组件方法
  methods: {
    GenreEvent (index) {
     console.log(index)
    },
    StatusEvent () {

    }
  },
  // 组件 DOM 已经更新
  updated () {},
  // 实例销毁之前调用。
  beforeDestroy () {},
  // Vue 实例销毁后调用,事件监听器会被移除
  destroyed () {}
}

</script>

<style scoped lang='less'>
@media(max-width: 600px) {
  .phoneMargin{
    margin-top: 10px;
  }
}
</style>
